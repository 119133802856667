<template>
  <div v-if="checkUser" class="customer-quick-menu">
    <!-- Dropdown -->
    <div class="position-relative d-flex justify-content-center">
      <transition name="fade">
        <b-card v-show="isMenuOpen" style="bottom: 100%; width: max-content" body-class="p-50" class="custom-dropdown border rounded position-absolute m-50">
          <div v-for="(item, key) in menuItems" :key="key">
            <b-link :to="{ name: item.route }" class="d-flex gap-10 align-items-center text-dark p-50 fs-16 qm-item">
              <feather-icon :icon="item.icon" />
              <span>{{ item.title }}</span>
            </b-link>
          </div>
        </b-card>
      </transition>
      <b-button variant="gradient-primary" class="btn-icon rounded-circle" @click="isMenuOpen = !isMenuOpen">
        <mdi-icon size="30" icon="Heart" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
      menuItems: [
        {
          title: "Kargo",
          icon: "TruckIcon",
        },
        {
          title: "İş İlanı",
          icon: "UsersIcon",
        },
        {
          title: "Akaryakıt",
          icon: "CircleIcon",
        },
        {
          title: "Araç Takip",
          icon: "MonitorIcon",
        },
        {
          title: "Sigorta",
          icon: "ShieldIcon",
        },
        {
          title: "Telekomunikasyon",
          icon: "SmartphoneIcon",
        },
      ],
    };
  },
  computed: {
    checkUser() {
      return sessionStorage.getItem("token") && this.$store.getters.user?.role?.group === "customer";
    },
  },
};
</script>

<style>
.customer-quick-menu {
  position: fixed;
  bottom: 5%;
  right: 80px;
  z-index: 99;
}
.qm-item {
  transition: all 0.2s ease-in-out;
}

.qm-item:hover {
  transform: translateX(4px);
}
</style>
